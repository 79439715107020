.linea-servicios {
    display: grid;
}

.linea-servicios>div {
    
}

.linea-servicios .banner {
    margin: 2rem auto auto auto !IMPORTANT;
}

.servicio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 2rem;
}

.servicio h1::after {
    content: "";
    display: block;
    width: 20%;
    border-bottom: 0.2rem solid var(--secondary-color);
}
