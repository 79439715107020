.contacto-body {
    margin-top: 3rem;
}

.contacto-body::after {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 120%;
    background: #f0f0f0aa;
}

.banner {    
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: var(--main-color);
    border-radius: 20px;
    color:white;    
    margin-bottom: 2rem;
}

.horario {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5rem;
    padding-top: 5rem;
    margin-bottom: 5rem !IMPORTANT;
}

.horario-mapa {        
    background: url(../../../public/img/pharmacist.png) rgba(32, 64, 142, 0.49);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    width: 25rem;
    height: 20rem;
    border-radius: 20px;
}

.horario-card {
    padding: 2rem 3rem 2rem 4rem;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -2rem;
    box-shadow: 2px 5px 10px rgb(0 0 0 / 10%);
    z-index: 10;
    background-color: #fff;
    border-radius: 20px;
    text-align: justify;
    font-size: 0.9rem;
  }