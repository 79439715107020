.marcalaboratorio {
    display: grid;    
    margin: auto;
  }
  
.marcalaboratorio-title {
    text-align: center;
    font-size: 1.5em;    
  }
  
.imgs {
    margin:auto;
  }
  
.imgs {
    width: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem 3rem;
    align-items: center;
    justify-content: center;
  }
  
.imgs {
    opacity: 70%;
    object-fit: cover;
  }