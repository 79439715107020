header {
    display: flex;
    width: 100%;
    height: 10vh;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    z-index: 1000;
    transition: 0.5s all;
}

.header-solid {
    background: white;
    height: 6vh;
    transition: 0.5s all;
}

a {
    text-decoration: none;
    padding: 20px;
    transition: 0.3s all;
}

a:hover {
    color: var(--secondary-color);
}

a:visited {
    color: var(--main-color)
}

.logo-img {
    display: flex;
    flex: 30;
    align-items: center;
    justify-content: center;
}

nav {
    flex: 70;
    display: flex;
}

nav ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: flex-end;
    align-items: center;
}

nav ul li {
    position: relative;
    padding: 0 1rem;
    cursor: pointer;
}

nav a {
    font-weight: 600;
    font-size: 12px;
}

nav ul li .button {
    background: var(--main-color);
    color: #ffff;
    border-radius: 20px;
    padding: 0.3rem 1.4rem;
}

nav ul li .button:hover {
    background: var(--secondary-color);
    color: #ffff;
    border-radius: 20px;
    padding: 0.8rem 2rem;
}


nav ul li .button i {
    margin-right: 1rem;
    font-size: 15px;
}