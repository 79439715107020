.principal {    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    max-height: 100%;
    height: 80vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.principal > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top left;
}