.contenedor{
    width: 400px;
    padding: 20px;
    background: rgb(255, 255, 255, 0.90);
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;    
}

.contenedor h2{
    font-size: 30px;
    text-align: center;
}

