.nuestrassedes-title {
    width: 38%;
    margin: auto;
}
.nuestrassedes-title h1 {
    font-size: 2.5rem;
    font-weight: 700;
}

.nuestrassedes-column {
    display: grid;
    grid-template-columns: 2fr 2fr; 
    grid-template-rows: 2fr 2fr;  
}

.nuestrassedes-column > div{
    display: flex;    
    padding-bottom: 2rem;
}

.nuestrassedes-column > div:nth-child(1){
    justify-content: flex-end;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.nuestrassedes-column > div:nth-child(2){
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.nuestrassedes-column > div:nth-child(3){
    justify-content: flex-end;
    padding-right: 2rem;    
}

.nuestrassedes-column > div:nth-child(4){
    padding-left: 2rem;    
}

