html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    --main-color: #20408E;
    --secondary-color: #ED6823;
    font-family: montserrat;
    min-height: 100%;
    text-align: center;
    color: var(--main-color);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1rem;
}

.form-group label {
    color: var(--secondary-color);
    font-weight: bold;
    padding: 0.5rem 0;
}

.form-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(100, 100, 100, 0.3);
    width: 100%
}


h1 {
    font-weight: 600 !IMPORTANT;
}

.responsive {
    width: 100%;
    height: auto !important;
}