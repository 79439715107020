.nuestrosservicios {
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr 2fr;
}

.nuestrosservicios-title {
    margin: auto;
}


.nuestrosservicios-title h1 {
    font-size: 2rem;
}

.nuestrosservicios .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
    grid-auto-rows: auto;
    grid-gap: 3rem;
    margin: auto;
    width: 70%;
}

.nuestrosservicios .card {
    display: grid;    
    background: white;
    border-radius: 20px;
    text-align: left;
    font-size: 1.5rem;
    transition: 0.5s all;
}

.card-hover:hover {
    background: var(--main-color);
    color: white;
}

.nuestrosservicios .card-header {
    color: var(--secondary-color);
    font-weight: 600;
    padding: 2rem 2rem 0rem 2rem;
}

.nuestrosservicios .card-body {
    padding: 1rem 1rem 0rem 2rem;
}

.nuestrosservicios .card-body > img {
    height: 100%;
}

.nuestrosservicios .card-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.4rem 2rem 2rem 2rem;
}

.bottom-line:after {
    content: "";
    display: block;
    width: 60%;
    border-bottom: 0.2rem solid #C67d42;
}

.bottom-line-1:after {
    content: "";
    display: block;
    width: 65%;
    border-bottom: 0.2rem solid #C67d42;
}

