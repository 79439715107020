body {
    margin: 0;
    font-family: montserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}