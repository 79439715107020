.formula {
    padding: 3rem 2rem;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: var(--secondary-color);
    transition: background-color 0.5s;
}

.formula:hover {  
    background-color: var(--main-color);
    color: white;
    transition: background-color 0.5s;
}