.portafoliodeproductos-title {
    width: 38%;
    margin: auto;
}

.portafoliodeproductos-title h1 {
    font-size: 2.5rem;
    font-weight: 700;
}

.accordion {
    width: 90%;
    margin: 2rem auto;
}

.accordion-item {
    background-color: #fff;
    color: #111;
    margin: 1rem 0;
    border-radius: 0.5rem;
    box-shadow:  2px 5px 10px rgb(0, 0, 0, 0.1);
}
.accordion-item-header {
    padding: 1rem 3rem 1rem 1rem;
    color: var(--secondary-color);
    min-height: 3.5rem;
    line-height: 1.25rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    box-shadow:  2px 5px 10px rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.accordion-item-header::after {
    content: "\002B";
    font-size: 2rem;
    position: absolute;
    right: 1rem;
}

.accordion-item-header.active::after {
    content: "\2212";
}

.accordion-item-body {
    display: grid;       
    margin-top: 3rem;
    gap: 3rem;
}

.alimentometabolismo > div{
    display: flex;    
    padding-bottom: 2rem;
}

.alimentometabolismo {
    width: 80%;
    padding-left: 2rem;
    margin: auto;
    text-align: justify;
}

.alimentometabolismo > h1 {
    color: var(--main-color)
}

.portafolioproductos-column {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
}
.portafolioproductos-column > div{
    display: flex;    
    padding-bottom: 2rem;
}

.portafolioproductos-column > div:nth-child(1){
    justify-content: flex-end;
    padding-right: 2rem;
   
}

.portafolioproductos-column> div:nth-child(2){
    padding-left: 2rem;
    
}

.portafolioproductos-column > div:nth-child(3){
    justify-content: flex-end;
   
}

.portafolioproductos-column > div:nth-child(4){
    padding-left: 2rem;    
}



  


