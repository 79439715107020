
.contactanos-form {
    background: rgba(255, 255, 255, 1);
    margin: auto;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.button {
    background: var(--main-color);
    color: #ffff;
    border-radius: 20px;
    padding: 0.8rem 2rem;
    border: 1px solid var(--main-color);
    width: 100%;
    font-weight: bold;
}

* {
    box-sizing: border-box;
}