.contactanos {
    display: grid;
    grid-template-rows: 1fr 2fr;
    background: url(../../../public/img/doctors-reading-paper-in-hospital.jpg) rgb(93, 41, 14);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    margin: 0 !IMPORTANT;
    padding: 4rem 1rem;
    border-top: 1px solid var(--secondary-color)
}

.contactanos-header {
    padding: 2rem 5rem 0 2rem;
    font-size: 1.5rem;
    text-align: left;
    color: white;
}

.contactanos-body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
}