.cobertura {
    width: 100%;
    background: url("../../../public/img/mapa_colombia.svg");
    background-position: center;
    background-size: 55%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    height: 100vh;
}

.cobertura h1::before {
    content: "";
    display: block;
    width: 30%;
    border-top: 0.3rem solid var(--main-color);
    margin: auto
}