footer {
    display:grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--main-color);
    color: white;
    margin-bottom: -1rem;
}

.footer-enlaces {
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 1rem 0;
}

.footer-enlaces > div:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0;
}

.footer-enlaces > div:nth-child(2) {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;    
}

.footer-contactenos {
    background-image: url(../../../public/img/Ellipse\ 30.png);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer-contactenos::after {   
   
}