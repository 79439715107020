.acordeon {
    width: 90%;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    height: auto;
    margin: auto;
}

.acordeon-header {
    text-align: left;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 0.5rem;
    min-height: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.6rem;
    color: var(--secondary-color);
    font-weight: bold;
}

.toggle > a::after {
    content: "\002B";
    font-size: 2rem;
}

.toggle.toggle-active > ::after {
    content: "\2212";
    font-size: 2rem;
}

.acordeon-body {
    width: 100%;
    line-height: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in;
}

.active {
    height: auto;
    overflow: auto;
    line-height: normal;
    padding: 2rem;
    transition: height 0.5s ease-in;
}