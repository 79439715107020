.nuestroproposito {
    display: grid;
    grid-template-rows: 1fr 2fr;    
}

.nuestroproposito-title {
    width: 38%;
    margin: auto;
}

.nuestroproposito-title h1 {
    font-size: 2.5rem;
    font-weight: 700;
}

.nuestroproposito-indicadores {    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 20rem), 1fr));
    grid-auto-rows: auto;    
    margin: 3rem auto;
    width: 85%;
}

.indicador {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;        
    font-size: 1.5rem;
    text-align: center;
    color: var(--main-color);    
    gap: 1rem;
}

.indicador-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.indicador-img > img {
    height: 5rem;
}

.indicador-title {
    font-size: 2.5rem;
    color: var(--secondary-color);
    font-weight: 1000;
    padding-top: 2rem;
}

.indicador-texto {
    font-size: 1rem;
    font-weight: 400;
}

.text-justify {
    text-align: justify;
}