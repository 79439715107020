.productosdestacados {
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr 2fr;
    background-color: rgba(196, 196, 196, 0.22);
    padding-bottom: 3em;
}

.productosdestacados-title {
    margin: auto;
}

.productosdestacados-title h1 {
    font-size: 2rem;
    font-weight: 600;
}

.productosdestacados .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin: auto;
    width: 80%;
}

.productosdestacados .card {
    display: grid;
    background: white;
    border: 2px solid #e7e7e7;
    border-radius: 20px;
    transition: 0.5s all;
}

.card-hover:hover {
    background: var(--main-color);
    color: white;
}

.productosdestacados .card-header {
    padding: 2rem 1rem 0 1rem;
    border-radius: 20px 20px 0 0;
}

.productosdestacados .card-body {
    padding: 1rem;
}

.productosdestacados .card-body>p {
    text-align: left;
}

.productosdestacados .card-body>img {
    height: 100%;
    margin: auto;
    border-radius: 10px;
}

.productosdestacados .card-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    width: 100%;
    background-color: rgba(245, 245, 245, 0.57);
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 0 0 20px 20px;
}

.bottom-line:after {
    content: "";
    margin-top: 1rem;
    top: 0;
    width: 30%;
    border-bottom: 0.1rem solid #C67d42;
}

.top-line:before {
    content: "";
    display: block;
    width: 60%;
    border-top: 0.2rem solid #C67d42;
    margin: 0.5rem 0
}